import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
// import { GatsbyImage } from "gatsby-plugin-image";

import ButtonSolid from "../../components/Button/ButtonSolid";

const StyledContent = styled.div`
  p {
    ${tw`text-[16px] leading-[1.5rem] font-normal`}
  }
`;
const ServiceSection1 = ({
  className,
  sectionTitle,
  sectionDesc,
  contents,
}) => {
  return (
    <section className="relative md:pb-0">
      <div className="block md:hidden">
        <img src={contents.bgimage.asset.url} alt={sectionTitle}></img>
      </div>
      <div className="container hidden md:block">
        <div className="grid gap-y-12  md:grid-cols-2">
          <div className="relative order-2   md:py-28 md:pr-10 lg:py-24 lg:pr-20 ">
            <div className="h-full overflow-hidden rounded-[8px]">
              <img src={contents.bgimage.asset.url} alt={sectionTitle}></img>
            </div>
          </div>

          <div className="order-2 flex flex-col justify-center md:order-2 md:py-28 md:pr-10 lg:py-24 lg:pr-20 ">
            <h1>{sectionTitle}</h1>
            <StyledContent>
              <p>{sectionDesc}</p>
            </StyledContent>
            <ButtonSolid className="" modal="modal-contact" text="Contact" />
          </div>
        </div>
      </div>
      <div className="block bg-primary-700 pb-6 md:hidden">
        <div className="container  ">
          <div className=" grid gap-y-4 md:grid-cols-2">
            <div className="relative order-2   md:py-28 md:pr-10 lg:py-24 lg:pr-20 ">
              <div className="hidden md:block">
                {/* {contents.bgimage && (
                  <GatsbyImage
                    class="rounded-t-full"
                    objectFit="cover"
                    image={contents.bgimage.asset.url}
                    alt={sectionTitle}
                    className="h-full w-full"
                  />
                )} */}
              </div>
            </div>

            <div className="order-2 flex flex-col justify-center text-center md:order-2 md:py-28 md:pr-10 lg:py-24 lg:pr-20">
              <StyledContent>
                <h1>{sectionTitle}</h1>
                <p>{sectionDesc}</p>

              </StyledContent>
              <ButtonSolid
                className=" mx-auto"
                modal="modal-contact"
                text="Contact"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceSection1;
