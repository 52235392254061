// la-mesa-assisted-living
// alzheimers-care
// dementia-care
// la-mesa-hospice
// respite-care-la-mesa
// senior-care-living-in-la-mesa

import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";

import PortableText from "../Blog/portableText";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  ul > li {
    ${tw`text-[16px] leading-[1.5rem] font-normal`}
  }
  ol {
    ${tw`list-decimal pl-7 mb-6 flex flex-col space-y-0.5`}
  }
  p {
    ${tw`text-[16px] leading-[1.5rem] font-normal`}
  }
`;

// const slides = [
//   {
//     // image: data.assistedLiving.childImageSharp.gatsbyImageData,
//     heading: "Assisted Living",
//     text: "RanchView assisted living residents receive help and support tailored to their needs.",
//     link: "/la-mesa-assisted-living/",
//   },
//   {
//     // image: data.alzheimersCare.childImageSharp.gatsbyImageData,
//     heading: "Alzheimer’s Care",
//     text: "Residents with Alzheimer’s disease receive personalized memory care that includes therapeutic activity programs.",
//     link: "/alzheimers-care/",
//   },
//   {
//     // image: data.dementiaCare.childImageSharp.gatsbyImageData,
//     heading: "Dementia Care",
//     text: "Our staff has specialized training in all forms of dementia and offers 24/7 care and supervision.",
//     link: "/dementia-care/",
//   },
//   {
//     // image: data.enrichingExperience.childImageSharp.gatsbyImageData,
//     heading: "Hospice Care",
//     text: "We have a hospice waiver that allows a terminally ill resident to stay with us, and we accommodate hospice services.",
//     link: "/la-mesa-hospice/",
//   },
//   {
//     // image: data.hospiceCare.childImageSharp.gatsbyImageData,
//     heading: "Respite Care",
//     text: "Caregivers need care too and that’s why we offer short-term relief that allows them to recharge.",
//     link: "/respite-care-la-mesa/",
//   },
//   {
//     // image: data.respiteCare.childImageSharp.gatsbyImageData,
//     heading: "Enriching Experiences",
//     text: "Life is meant to be lived, and we’re committed to ensuring our residents continue to live life to the fullest.",
//     link: "/senior-care-living-in-la-mesa/",
//   },
// ];

const ServiceSection2 = ({
  headingLevel,
  phonenumber,
  className,
  title1,
  description1,
  title2,
  description2,
  title3,
  description3,
  sectionNumber,
  arrary1,
}) => {
  return (
    <>
      <section className={` ${className || "mb-20 md:mb-32"}`}>
        <div className="container">
          <div className="grid grid-cols-1 gap-y-10 md:grid-cols-12 md:gap-x-12 lg:gap-x-24">
            <div className="md:col-span-6">
              <StyledContent>
                <div className="mb-0">
                  {title1 && (
                    <h2 className="pt-10 md:pt-0 lg:max-w-md ">{title1}</h2>
                  )}
                </div>
                <div className="mb-12 md:mb-16">
                  <p className="flex-initial">{description1}</p>
                </div>

                <div>
                  {title3 && <h3 className="heading-three">{title3}</h3>}
                  <div>
                    {description3 && <PortableText blocks={description3} />}
                  </div>
                </div>
              </StyledContent>
            </div>
            <div className="md:col-span-6">
              <div className="grid items-center gap-y-4 md:grid-cols-1 ">
                <ul className="flex h-full flex-col justify-center ">
                  {arrary1 &&
                    arrary1.map((content) => (
                      <li className="flex py-[5px]">
                        <svg
                          class="mr-2 h-6 w-6 flex-none fill-primary-400 stroke-secondary-400 stroke-2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <circle cx="12" cy="12" r="11" />
                          <path
                            d="m8 13 2.165 2.165a1 1 0 0 0 1.521-.126L16 9"
                            fill="none"
                          />
                        </svg>
                        <p className="flex-initial">{content}</p>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceSection2;
